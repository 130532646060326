<template>
  <span class="form-control switch">
    <span class="flex-fill">
      <slot></slot>
    </span>
    <label>
      <input type="checkbox"
             :id="name"
             :name="name"
             v-model="checked"
             :value="val"
             @change="onChange" />
      <span></span>
    </label>
  </span>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    name: String,
    val: {
      type: Boolean,
      default: true,
    },
    value: Boolean,
  },
  data() {
    return {
      checkedProxy: false,
    }
  },
  computed: {
    checked: {
      get() {
        return this.value
      },
      set(val) {
        this.checkedProxy = val
      },
    },
  },
  methods: {
    onChange(e) {
      this.$emit('input', this.checkedProxy)
    },
  },
}
</script>